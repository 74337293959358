.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.home h1 {
  font-size: 5vw;
}

.home h2 {
  font-size: 4vw;
}

.home .homeButtonContainer {
  min-height: 5vh;
  min-width: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 786px) {
  .home {
    justify-content: flex-start;
    margin-top: 5vh;
    height: 50vh;
  }

  .home h1 {
    font-size: 12vw;
  }

  .home h2 {
    font-size: 10vw;
  }
}
