.projects {
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  justify-content: space-around;
  margin-top: 5vw;
  max-width: 1200px;
}

.projectsContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 786px) {
  .projects {
    max-width: initial;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: auto;
  }

  .projectsContainer {
    height: auto;
  }
}
