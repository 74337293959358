.about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1vw;
  width: 100vw;
}

.about img {
  width: 35vw;
  border: 1.5px rgb(128, 58, 58) solid;
  padding: 1vw;
  border-radius: 20px;
}

.about p {
  width: 75%;
  font-size: 2vw;
}

@media only screen and (max-width: 786px) {
  .about {
    padding: 1vw 0 1vw 0;
  }

  .about img {
    width: 75vw;
  }

  .about p {
    width: 80%;
    font-size: 4.5vw;
    text-align: center;
  }
}
