@keyframes pageLoad {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(calc(-100% + 10px));
  }
}

.sidebarContainer {
  height: 70vh;
  width: 10vw;
  min-width: 10vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 65px;
}

#sidebar {
  animation: 1.5s ease-out 0s 1 pageLoad;
  background: lightblue;
  border-radius: 0 10px 10px 0;
  transform: translateX(calc(-100% + 10px));
  transition: transform 0.4s cubic-bezier(0.45, 0, 0.2, 1) 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.sidebarContainer:hover #sidebar {
  transform: translateX(0);
  transition-delay: 0s;
}

#sidebar a {
  text-decoration: none;
  color: blanchedalmond;
}

#sidebar h3 {
  margin: 0;
  font-size: 2vw;
}

@media only screen and (max-width: 786px) {
  #sidebar {
    animation: none;
    transform: none;
    transition: none;
    border-radius: 0;
  }

  #sidebar h3 {
    font-size: 6vw;
  }

  .sidebarContainer {
    height: 20vh;
    width: 100%;
    top: initial;
    position: initial;
  }
}
