.projectBox {
  max-width: 500px;
  width: 35vw;
  height: 60vh;
  margin-bottom: 7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1.5vw;
}

.projectBox img {
  max-height: 50%;
  max-width: 30vw;
  width: auto;
  height: auto;
  border: 1px black solid;
}

.skills {
  font-weight: 700;
}

@media only screen and (max-width: 786px) {
  .projectBox {
    max-width: initial;
    width: 90%;
    height: auto;
  }

  .projectBox img {
    max-height: 50vh;
    max-width: 80vw;
  }
}
