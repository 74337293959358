.button {
  border-radius: 7px;
  background-color: #25292e;
  width: 100%;
  color: #ffffff;
  transition: background-color 250ms ease-out 50ms, color 250ms ease-out 50ms;
}

.button:hover {
  background-color: #c7d7eb;
  cursor: pointer;
  color: #484141;
}

.button:focus {
  outline: none;
}

.button:hover:active {
  box-shadow: -1px -1px grey;
}

.buttonMedium {
  width: 10vw;
  height: 6vh;
  font-size: 1.5vw;
}

.buttonLarge {
  width: 15vw;
  height: 7vh;
  font-size: 2vw;
}

@media only screen and (max-width: 786px) {
  .buttonSmall {
    padding: 4vw;
  }
  .buttonMedium {
    width: 40vw;
    font-size: 4.5vw;
  }

  .buttonLarge {
    width: 65vw;
    height: 10vh;
    font-size: 8vw;
  }
}
