.body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 90vh;
}

@media only screen and (max-width: 786px) {
  .body {
    flex-direction: column;
  }
}
