.contact {
  width: 100vw;
  height: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.contact a {
  text-decoration: none;
  color: rgb(136, 82, 79);
  font-weight: 700;
  margin: 1vh 0;
  cursor: pointer;
}

.contact img {
  margin-top: 15vh;
  width: 20vw;
  border-radius: 100px;
}

.contact p {
  margin: 0;
}

.buttonHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20vw;
}

@media only screen and (max-width: 786px) {
  .buttonHolder {
    flex-direction: column;
  }

  .contact img {
    margin-top: 5vh;
    width: 60vw;
  }

  .contact p {
    font-size: 5vw;
  }

  .contact h3 {
    font-size: 7vw;
  }
}
